<mat-card id="card" fxLayout="column" class="mainContentBody">
  <mat-card-content fxFlex [style.overflow]="'auto'" fxLayout="column" class="mainContentBodyContent" *ngIf="this.form">

    <div fxLayout="column" fxFlex="40" class="form">
      <h2>Data Integration</h2>

      <div fxLayout="column">
        <form [formGroup]="form" fxLayout="column" class="responsive-mat-form">
          <!-- Id field -->
          <mat-form-field>
            <input matInput formControlName="id" placeholder="Id" readonly="true" />
          </mat-form-field>

          <!-- Name field -->
          <ng-container *ngIf="isDetail; else created">
            <mat-form-field>
              <input matInput formControlName="dataIntegrationName" placeholder="Name" readonly="true" />
            </mat-form-field>
          </ng-container>
          <ng-template #created>
            <mat-form-field>
              <mat-label>Name</mat-label>
              <input type="text" matInput required [matAutocomplete]="auto" (input)="inputNameChanged()" formControlName="dataIntegrationName" #name  >
              <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectedName($event.option.value)">
                <mat-optgroup label="Block Chain Types">
                  <mat-option *ngFor="let blockchainType of blockchainTypesFiltered" [value]="blockchainType">
                    {{blockchainType}}
                  </mat-option>               
                </mat-optgroup>
                <mat-optgroup label="Exchange Types">
                  <mat-option *ngFor="let exchangeType of exchangeTypeFiltered" [value]="exchangeType">
                    {{exchangeType}}
                  </mat-option>
                </mat-optgroup>
              </mat-autocomplete>
            </mat-form-field>
          </ng-template>

          <!-- Icon url field -->
          <mat-form-field>
            <input matInput formControlName="dataIntegrationIconUrl" placeholder="Icon URL"
              [readonly]="form.get('deleteDate').value">
          </mat-form-field>

          <!-- Guide field -->
          <mat-form-field>
            <input matInput formControlName="dataIntegrationGuideUrl" placeholder="Guide URL"
              [readonly]="form.get('deleteDate').value">
          </mat-form-field>

          <!-- Message API field -->
          <mat-form-field>
            <input matInput formControlName="dataIntegrationMessage" placeholder="Message API"
              [readonly]="form.get('deleteDate').value">
          </mat-form-field>

          <!-- Message CSV field -->
          <mat-form-field>
            <input matInput formControlName="dataIntegrationMessageCsv" placeholder="Message CSV"
                   [readonly]="form.get('deleteDate').value">
          </mat-form-field>

          <!-- Status field -->
          <mat-form-field>
            <mat-select formControlName="dataIntegrationStatus" placeholder="Status"
              [disabled]="form.get('deleteDate').value">
              <mat-option *ngFor="let status of dataintegrationStatus" [value]="status">
                {{status}}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <!-- Type field -->
          <mat-form-field>
            <mat-select formControlName="dataIntegrationType" placeholder="Data Integration Type"
              [disabled]="form.get('deleteDate').value">
              <mat-option *ngFor="let positionContainerType of positionContainerTypes" [value]="positionContainerType">
                {{positionContainerType}}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <!-- Order field -->
          <mat-form-field>
            <input matInput formControlName="order" placeholder="Order" [readonly]="form.get('deleteDate').value">
          </mat-form-field>

          <!-- Key Field -->
          <dataintegration-detail-chiplist [control]="form.get('keyList')"></dataintegration-detail-chiplist>

          <mat-checkbox formControlName="dataIntegrationBeta">Beta</mat-checkbox>
          
          <div class="checkbox-container" formGroupName="checkboxGroup" >
            <mat-checkbox formControlName="dataIntegrationIsAPI">API</mat-checkbox>
            <mat-checkbox formControlName="dataIntegrationIsCSV">CSV</mat-checkbox>
          </div>
        </form>

        <mat-card-actions>
          <button mat-raised-button (click)="backConfirm('dataintegration')" color="primary">Back</button>
          <button mat-raised-button (click)="save()"
            [disabled]="(form.invalid || !form.dirty) || form.get('deleteDate').value">
            Save
          </button>
          <delete-button (customClick)="deleteDataIntegration()"
                         message='Sei sicuro di voler cancellare questo Data Integration?'
                         [disabled]="!form.get('id').value || form.get('deleteDate').value">
          </delete-button>
        </mat-card-actions>
      </div>
    </div>

  </mat-card-content>

</mat-card>
